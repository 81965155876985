export const UNDERSTANDINGS = [
  "who will understand, and the",
  "they only understand it whom",
  "finely should understand it",
  "claim to understand, or to",
  "of ghostly understanding and less",
  "and true understanding, well to",
  "have very understanding and true",
  "in divine understanding of divine",
  "might not understand it",
  "inward subtle understanding with great",
  "they shall misunderstand it, all",
  "world, and understand it into",
  "of the understanding of Love",
  "dwelleth in understanding and in",
  "ghostly to understand, it fareth",
  "given the understanding for no",
  "in her understanding",
  "in her understanding And not",
  "hard to understand But for",
  "they shall understand it well",
  "Reason, that understandeth eagerly and",
  "without any understanding or painfulness",
  "Also, another understanding there is,",
  "beams, she understandeth well by",
  "of virtues, understand it not",
  "men may understand it, except",
  "by this understanding is she",
  "so, they misunderstand it but",
  "attain nor understand, for all",
  "best in well-understanding Oh, what",
  "God’s love understand it all",
  "same, to understand",
  "Hear and understand, ye auditors",
  "the true understanding of these",
  "saith the understanding of Reason,",
  "Reason, I understand that the",
  "saith the understanding of Reason,",
  "thus to understand the saying",
  "nature O understanding of Reason,",
  "grain Thine understanding is so",
  "have the understanding of the",
  "But the understanding of divine",
  "made free, understandeth it without",
  "O thou understanding of Reason,",
  "of the understanding of Love,",
  "of Love, understand now the",
  "of thy misunderstanding If this",
  "hard to understand, to their",
  "to their understanding so that",
  "may none understand by mine",
  "by mine understanding, unless they",
  "none shall understand this book",
  "to mine understanding, unless they",
  "by this understanding this soul",
  "is not, understand wholly of",
  "see by understanding of spirit",
  "by the understanding, that doth",
  "except by understanding, and we",
  "of the understanding of the",
  "for the understanding that giveth",
  "light of understanding the nighing",
  "by this understanding this soul",
  "by this understanding this soul",
  "herself Now understand, Reason, saith",
  "me the understanding, who hast",
  "unto good understanding but mis-sayers",
  "of such understanding I have",
  "the simple understanding of other",
  "their damage misunderstand it",
  "hath twain understandings, for though",
  "I cannot understand them",
  "ye to understand of these",
  "I may understand these words,",
  "if ye understand it",
  "and the understanding and the",
  "saith Love, understand thy question",
  "love Now understand the remnant,",
  "is to understand without none",
  "in good understanding Before this",
  "of mine understanding, than that",
  "within mine understanding And for",
  "that I understand perfectly, saith",
  "may not understand it but",
  "may not understand it by",
  "Church, we understand it and",
  "mind and understanding and will,",
  "is, to understand it better,",
  "that any understanding, however illumined,",
  "of the understanding of love,",
  "never ye understand it And",
  "according to understanding, this is",
  "they only understand it whom",
  "finely should understand it, saith",
  "from the understanding that comes",
  "do not understand anything it",
  "that ye understand finely this",
  "to the understanding of the",
  "third death understandeth none alive,",
  "this life, understand it well",
  "Love We understand this well,",
  "in the understanding lieth the",
  "we should understand it We",
  "by the understanding that we",
  "that they understand not the",
  "whoever will understand it and",
  "you to understand, ye that",
  "ye should understand it and",
  "without strong understanding, subtle and",
  "few your understandings of the",
  "more high understanding, one beyond",
  "better to understand, of the",
  "saith Love, understand I come",
  "wit and understanding that she",
  "these divine understandings, by the",
  "gloss O understand holily, saith",
  "hard to understand for them",
  "in the understanding lieth all",
  "the other understand without saying",
  "most men understand a thing",
  "what ye understand by this",
  "what we understand by this",
  "this We understand, say they,",
  "be your understandings ye take",
  "enough to understanders, to understand",
  "understanders, to understand the remnant",
  "them that understand it",
  "fall Oh, understand it by",
  "self Now understand, auditors of",
  "Now understand by nobleness",
  "nobleness of understanding the gloss",
  "to the understanding thereof, offer",
  "in the understanding of this",
  "for God understand it, ye",
  "ye will understand it He",
  "value, nor understand anything unless",
  "they did understand it any",
  "heart without understanding, as long",
  "that Reason understandeth, but of",
  "failing Now understand, if ye",
  "of her understanding, but God",
  "wit, by understanding, of the",
  "ye will understand it or",
  "ye shall misunderstand it, for",
  "those who understand not the",
  "is to understand, that he",
  "whoso should understand the truth",
  "who understandeth that this",
  "may one understand by this",
  "is to understand when the",
  "may ye understand how the",
  "soul Now understand, since it",
  "may better understand what thing",
  "stupid understanders Take we",
  "by which understanding groweth, that",
  "Those that understand rest, and",
  "and the understanding is the",
  "substance of understanding of love",
  "otherwise I understand it not",
  "agreeable, the understanding is of",
  "may ye understand how my",
  "comes to understand the problems",
  "incapable of understanding this Note",
  "to the understanding of her",
  "understand, comprehend",
  "If ye understand not this,",
  "But in well-understanding it is",
  "may not understand their language",
  "this deepness understand, nor the",
  "this one understanding of this",
  "book will understand Take that",
  "of love understand it in"
];

export const KNOWINGS = [
  "manuscript was unknown It has",
  "not even known that she",
  "he was unknown to him,",
  "translations are known and one",
  "might have known the exact",
  "wishes to acknowledge a great",
  "mystical, are known to have",
  "Fountains we know a good",
  "balanced We know that has",
  "from one unknown sea into",
  "We only know what he",
  "translator was unknown to him,",
  "of the unknown fourteenth-century mystics",
  "is commonly known It is",
  "As the knowledge of her",
  "deepens, her knowledge of God’s",
  "above intellectual knowledge of the",
  "the immediate knowledge of God",
  "set above knowing This is",
  "glorification of not-knowing and not-willing",
  "Love and Unknowing sometimes at",
  "a dark knowing of God",
  "Cloud of Unknowing, in this",
  "mode of knowing God, to",
  "his mystical knowledge a keen",
  "century are known to our",
  "by experimental knowledge, and most",
  "these we know we shall",
  "antithesis between knowledge and unknowing,",
  "knowledge and unknowing, willing and",
  "life We know that the",
  "Cloud of Unknowing comes nearest",
  "Cloud of Unknowing, a small",
  "is I know not, nor",
  "I may know the divine",
  "of the knowing of the",
  "more she knoweth that she",
  "that she knoweth not the",
  "soul hath knowing of the",
  "perfectly she knoweth that she",
  "that she knoweth not, with",
  "is not known but of",
  "plenty of knowing, and becometh",
  "plenty of knowing, and becometh",
  "a day known what control",
  "and he knoweth what is",
  "them without knowing or being",
  "live of knowing of love",
  "therefrom for knowing and love",
  "nor have knowing of themselves",
  "for God knoweth well that",
  "some knowing And Love",
  "And the unknown",
  "lightened of knowing",
  "have some knowing, at the",
  "increase in knowledge of the",
  "this soul knows of herself",
  "great inward knowing, by the",
  "such great knowledge that though",
  "all the knowing of all",
  "was never known nor never",
  "shall be known She loveth",
  "all the knowing that all",
  "all the knowing, the love,",
  "none may know, which may",
  "not be known No soothly",
  "all the knowing that they",
  "and I know not what",
  "not be known except by",
  "of divine knowings and the",
  "when clear knowledge knoweth that",
  "clear knowledge knoweth that there",
  "the souls know not where",
  "naught, she knoweth all and",
  "and she knoweth naught She",
  "naught She knoweth all, saith",
  "this clerks know",
  "in the knowing of him",
  "seek to know too much",
  "light of knowing, 60 that",
  "made free knoweth all and",
  "and she knoweth naught She",
  "naught She knoweth by the",
  "needeth to know for salvation",
  "and she knoweth naught, for",
  "for she knoweth naught of",
  "this soul knoweth all and",
  "and she knoweth naught She",
  "if ye know that no",
  "be, ye know all three,",
  "do, ye know also but",
  "dignity that know neither ye",
  "Church not know",
  "Reason, who knoweth then",
  "forgotten, That knoweth only God,",
  "saith Love, knoweth not Holy",
  "that none knoweth such souls",
  "that none knoweth these souls",
  "them, nor know them but",
  "such, would know them that",
  "they should know their fellows",
  "such have knowledge of the",
  "creatures, nevertheless know they not",
  "for none knoweth them, but",
  "or wist know wittance or",
  "or witting knowledge",
  "light of knowing the identification",
  "enough to know that God",
  "among us, unknown The following",
  "the true knowledge that she",
  "the upraised knowledge of the",
  "by the knowledge of her",
  "of the knowing of the",
  "fausets this knoweth the manhood",
  "perceiving, of knowing by subtlety",
  "Love, have knowing of the",
  "without having knowing, so they",
  "glimmering of knowing of the",
  "clear in knowing, that she",
  "pure love knoweth well moreover",
  "nor in knowing, nor in",
  "do I know, saith this",
  "soul this knoweth he Now",
  "Love, you know better than",
  "that none knoweth but he",
  "work I know well, saith",
  "which he knoweth and I",
  "and I know not, than",
  "which I know and which",
  "I shall know, than I",
  "which I know And therefore",
  "me the knowing of the",
  "which I know, that I",
  "me this knowledge continually",
  "from the knowledge of the",
  "and clear knowledge and valuation",
  "in the knowledge of the",
  "of God, unknown and unknowable",
  "unknown and unknowable the soul",
  "in my knowledge",
  "If you know perfectly your",
  "come to knowing your naught,",
  "well you know, that I",
  "in the knowledge of his",
  "his divine knowing, that I",
  "peace, who know the great",
  "may none know in this",
  "in their knowing, all those",
  "shall be known and your",
  "you can know your words",
  "me the knowing, and behold",
  "of divine knowing, Lady Love,",
  "loving and knowing and lauding",
  "And I acknowledge that I",
  "Do you know this saith",
  "than to know this",
  "praise nor know of worthy",
  "wrong, and know that they",
  "in this knowing of their",
  "in her knowing",
  "Son, who knoweth that she",
  "in the knowledge of wisdom",
  "so great knowledge as is",
  "being without knowing nor feeling,",
  "to the knowing of their",
  "may not know of their",
  "for their knowing is too",
  "little to know their loss",
  "here the knowledge of their",
  "men may know naught No,",
  "men shall know in heaven,",
  "men shall know, were naught",
  "more, the knowing of one",
  "is not known but by",
  "the great knowledge that dwelleth",
  "beyond our knowing The least",
  "by the knowing of her",
  "that the knowing of this",
  "come to knowing of more,",
  "that she knoweth not of",
  "in the knowing of him,",
  "beyond our knowledge Ah, ah,",
  "come into knowing of her",
  "that she knoweth that neither",
  "nor others know anything of",
  "in the knowing of God",
  "in his knowledge should have",
  "every man know that this",
  "she may know the secrets",
  "given this knowledge from him",
  "and the knowing of my",
  "to divine knowledge, for it",
  "the beatific knowledge in the",
  "not even know her own",
  "of not- knowing and not-willing",
  "for she knows the truth",
  "have no knowing that there",
  "without their knowing They fare",
  "have well acknowledged it in",
  "for she knoweth never but",
  "Light She knoweth the All",
  "and these know well that",
  "have no knowing of that",
  "or in knowing, or in",
  "gift I know not, saith",
  "so great knowing of God",
  "by divine knowing And the",
  "this divine knowing taketh from",
  "her the knowing of God’s",
  "that I know him, it",
  "taketh the knowledge of him",
  "I no knowing have of",
  "that I know myself, and",
  "also the knowing of myself,",
  "I no knowing have of",
  "than to know this",
  "for the knowledge of God",
  "apparitio gives knowledge of God’s",
  "without conscious knowledge of God",
  "of immediate knowledge and the",
  "of further knowledge",
  "sweet country unknown, more verily",
  "for we know well that",
  "to the knowing of his",
  "of divine knowing work in",
  "sins be known before all",
  "troubled in knowing, and encumbered",
  "men may know their friend",
  "your own knowledge But if",
  "of divine knowing, and the",
  "of divine knowing and of",
  "certainty of naught-knowing and into",
  "that, they know nothing for",
  "by divine knowing, nor by",
  "say I know not what",
  "wise, that know not for",
  "they that know cannot put",
  "of long known, that there",
  "may not know nor love",
  "is a well-known illustration in",
  "all thing knowest, O my",
  "Master, I know not Sweet",
  "book for knowledge which is",
  "ye shall know in this",
  "being They know it not",
  "become, 1 know this thing,",
  "it well, know altogether that",
  "her, I know not why",
  "ever I know you, but",
  "that he knoweth that which",
  "is the knowing that she",
  "that I know the questions",
  "these orders, know it not",
  "all creature’s knowledge, where creatures",
  "have no knowing thereof",
  "which none knoweth but Truth",
  "soul I know nothing better",
  "default of knowing he that",
  "that giveth knowing to a",
  "labour, and knowing may not",
  "and the knowing is the",
  "soul This knowing is of",
  "this, without knowing of ourselves",
  "for none knoweth it unless",
  "which she knows She would",
  "to be known to mankind",
  "to be known by my",
  "thing than knowing of the",
  "me the knowing of the",
  "shall be known This bounty",
  "never been known, neither was",
  "God is known and also",
  "than to know the divine",
  "God is known by me",
  "me is known, I am",
  "but the knowing of his",
  "nature is known by the",
  "knowledge of the",
  "receives infused knowledge and comes",
  "And the knowing of this",
  "of divine knowing her naught,",
  "deepness of knowing of her",
  "in this knowing by truth,",
  "may not know her wickedness,",
  "by the knowing of these",
  "liveth of knowing of the",
  "bounty This knowing of the",
  "Nor she knoweth naught but",
  "infused immediate knowledge, not by",
  "by the knowledge that comes",
  "man’s reason know nothing of",
  "me, I know not what",
  "for I know not your",
  "as I know naught of",
  "is, I know not of",
  "that I know of my",
  "me the knowing that I",
  "come to knowing Thus they",
  "me very knowing of his",
  "Church cannot know her",
  "of the knowledge, so that",
  "ye well know, by which,",
  "search for knowledge These increase",
  "stages, was known and to",
  "and a knowledge of God",
  "indifference and unknowing, which are",
  "but is known through species"
];
